import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/login.vue'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)
const changePush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return changePush.call(this, location).catch(err => err);
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter:(to,form,next) => {
      if(getToken()){
        next()
      }else{
        next('/login')
      }
    },
    children:[
      {
        path: '/order',
        name: 'order',
        component: () => import('../views/order/order.vue'),
        meta: { title: '订单信息' }
      },
      {
        path: '/recycleBin',
        name: 'recycleBin',
        component: () => import('../views/recycleBin/recycleBin.vue'),
        meta: { title: '文案管理' }
      },
      {
        path: '/setting',
        name: 'setting',
        component: () => import('../views/setting/setting.vue'),
        meta: { title: '修改密码' }
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/user.vue'),
        meta: { title: '客服管理' }
      },
      {
        path: '/userMember',
        name: 'userMember',
        component: () => import('../views/userMember/userMember.vue'),
        meta: { title: '会员管理' }
      },
      {
        path: '/shipManage',
        name: 'shipManage',
        component: () => import('../views/shipManage/shipManage.vue'),
        meta: { title: '分类管理' }
      },
      {
        path: '/license',
        name: 'license',
        component: () => import('../views/license/license.vue'),
        meta: { title: '营销日历' }
      },
      {
        path: '/customer',
        name: 'customer',
        component: () => import('../views/customer/customer.vue'),
        meta: { title: '用户管理' }
      },
      {
        path: '/banner',
        name: 'banner',
        component: () => import('../views/Partner/Partner.vue'),
        meta: { title: 'banner管理' }
      },
      {
        path: '/advertisment',
        name: 'advertisment',
        component: () => import('../views/advertisment/advertisment.vue'),
        meta: { title: '专题管理' }
      },
      {
        path: '/activity',
        name: 'activity',
        component: () => import('../views/activity/activity.vue'),
        meta: { title: '活动管理' }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

export default router
